
import GCards from './goose/g-cards';
import GSpacer from './goose/g-spacer';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-cards',
  components: {
    GCards,
    GSpacer,
  },
  mixins: [cmsComponent],
};
